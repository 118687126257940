import { ReactComponent as btnCross12 } from './btn-cross-12.svg';
import { ReactComponent as btnCross18 } from './btn-cross-18.svg';
import { ReactComponent as btnCross24 } from './btn-cross-24.svg';
import { ReactComponent as foldingArrow12 } from './folding-arrow-12.svg';
import { ReactComponent as foldingArrow18 } from './folding-arrow-18.svg';
import { ReactComponent as foldingArrow24 } from './folding-arrow-24.svg';
import { ReactComponent as accept12 } from './accept-12.svg';
import { ReactComponent as accept18 } from './accept-18.svg';
import { ReactComponent as accept24 } from './accept-24.svg';
import { ReactComponent as search12 } from './search-12.svg';
import { ReactComponent as search18 } from './search-18.svg';
import { ReactComponent as search24 } from './search-24.svg';
import { ReactComponent as calendar12 } from './calendar-12.svg';
import { ReactComponent as calendar18 } from './calendar-18.svg';
import { ReactComponent as calendar24 } from './calendar-24.svg';
import { ReactComponent as info12 } from './info-12.svg';
import { ReactComponent as info18 } from './info-18.svg';
import { ReactComponent as info24 } from './info-24.svg';
import { ReactComponent as help10 } from './help-fill-10.svg';
import { ReactComponent as help16 } from './help-fill-16.svg';


export const systemIcons: { [key: string]: any } = {
    '18': {
        clear: btnCross12,
        foldingArrow: foldingArrow12,
        accept: accept12,
        search: search12,
        calendar: calendar12,
        info: info12,
        help: help10,
    },
    '24': {
        clear: btnCross12,
        foldingArrow: foldingArrow12,
        accept: accept12,
        search: search12,
        calendar: calendar12,
        info: info12,
        help: help10,
    },
    '30': {
        clear: btnCross18,
        foldingArrow: foldingArrow18,
        accept: accept18,
        search: search18,
        calendar: calendar18,
        info: info18,
        help: help10,
    },
    '36': {
        clear: btnCross18,
        foldingArrow: foldingArrow18,
        accept: accept18,
        search: search18,
        calendar: calendar18,
        info: info18,
        help: help16,
    },
    '42': {
        clear: btnCross18,
        foldingArrow: foldingArrow18,
        accept: accept18,
        search: search18,
        calendar: calendar18,
        info: info18,
        help: help16,
    },
    '48': {
        clear: btnCross24,
        foldingArrow: foldingArrow24,
        accept: accept24,
        search: search24,
        calendar: calendar24,
        info: info24,
        help: help16,
    },
    '60': {
        clear: btnCross24,
        foldingArrow: foldingArrow24,
        accept: accept24,
        search: search24,
        calendar: calendar24,
        info: info24,
        help: help16,
    },
    'none': {
        clear: btnCross18,
        foldingArrow: foldingArrow18,
        accept: accept18,
        search: search18,
        calendar: calendar18,
        info: info18,
        help: help16,
    },
};